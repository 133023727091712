<template>
  <b-form-group
    label="Medya Kanalı"
    label-for="id_press_types"
  >
    <validation-provider
      #default="{ errors }"
      name="Medya Kanalı"
      rules="required"
    >
      <v-select
        id="id_press_types"
        v-model="itemData.id_press_types"
        :options="dataList"
        :reduce="item => item.id"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'PressTypes',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  computed: {
    itemData() {
      return this.$store.getters['press/dataItem']
    },
    dataList() {
      return this.$store.getters['pressTypes/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('pressTypes/getDataList')
    },
  },
}
</script>
