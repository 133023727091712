<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <press-types />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <title-text />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <nDate />
        </b-col>
        <b-col
          cols="12"
        >
          <summary-content />
        </b-col>
        <b-col
          cols="12"
        >
          <content-text />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        cols="12"
      >
        <image-card />
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import PressTypes from '@/views/Admin/Press/elements/PressTypes.vue'
import TitleText from '@/views/Admin/Press/elements/TitleText.vue'
import SummaryContent from '@/views/Admin/Press/elements/SummaryContent.vue'
import ContentText from '@/views/Admin/Press/elements/ContentText.vue'
import ImageCard from '@/views/Admin/Press/elements/ImageCard.vue'
import nDate from '@/views/Admin/Press/elements/nDate.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    PressTypes,
    TitleText,
    SummaryContent,
    ContentText,
    ImageCard,
    nDate,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['press/dataItem']
    },
  },
}
</script>
